@use '@angular/material' as mat;
@import 'materialTheme';

@mixin sidebar {
    display: flex;

    main, .main {
        flex: 1;
    }

    aside, .aside {
        width: 280px;
        margin-left: 30px;

        .box, .dialog {
            box-sizing: border-box;
            width: 100%;
        }

        .dialog:first-of-type {
            margin-top: 0;
        }
    }
}

@mixin buttonTitle {
    display: flex;
    align-items: center;

    span:not(.titleControls) {
        flex: 1;
    }

    .mat-mdc-button-base {
        margin-left: 8px;
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        row-gap: 8px;
    }
}

@mixin bubble {
    position: absolute;
    background-color: mat.get-color-from-palette($tyyp-primaryOrange);
    color: white;
    padding: 15px 20px;
    font-size: 20px;
    width: auto;
    white-space: nowrap;
    z-index: 100;
    border-radius: 22px;

    &:after {
        content: url('assets/images/layout/bubble.svg');
        position: absolute;
        bottom: -8px;
        left: 11px;
    }
}

@mixin activityBox {
    margin-bottom: 0;
    box-sizing: border-box;
    height: calc(100dvh - 200px);
    position: relative;
    overflow: hidden;

    @media only screen and (max-height: 800px) {
        height: calc(100dvh - 150px);
    }
}

@mixin orderedList {
    counter-reset: li;
    list-style-type: none;
    padding-left: 0;

    & > li {
        display: flex;
        gap: 10px;
        margin: 12px 0;

        & > span, & > div {
            flex: 1;
            padding-top: 4px;

            & > :first-child {
                margin-top: 0;
            }

            & > h2:first-child {
                margin-top: -6px;
            }
        }

        &:before {
            content: counter(li);
            counter-increment: li;
            background-color: mat.get-color-from-palette($tyyp-primaryBlue);
            color: white;
            font-weight: bold;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            text-align: center;
        }
    }
}
