@use '@angular/material' as mat;

/*
font-family: 'Noto Sans Display', sans-serif;
font-family: 'Noto Sans Mono', monospace;
 */

@include mat.core();
@import 'materialTheme.scss';
@include mat.all-component-themes($tyyp-theme);

@import 'mixins.scss';
@import 'chapterThemes.scss';

// elements
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Noto Sans Display', sans-serif;
}

img {
    width: auto;
    height: auto;
}

a, a:visited, .linkBtn, .linkBtn:visited {
    color: mat.get-color-from-palette($tyyp-primaryBlue);
}

a:hover, .linkBtn:hover {
    color: mat.get-color-from-palette($tyyp-primaryBlue, 700);
}

.linkBtn {
    background-color: transparent;
    border: none;
    font-family: 'Noto Sans Display', sans-serif;
    text-align: left;
}

h1, h2, h3, h4, h5, h6 {
    color: mat.get-color-from-palette($tyyp-primaryBlue);
}

h1 .mat-mdc-mini-fab {
    vertical-align: middle;
    margin-right: 8px;
}

hr {
    border: 0;
    border-top: 1px solid mat.get-color-from-palette($tyyp-primaryGray, 700);
    margin: 15px 0;
    padding: 0;
}

p {
    font-size: 14px;
}

table {
    width: 100%;
}

// useful classes
.invisible {
    display: none !important;
}

.alignRight {
    text-align: right !important;
}

.alignLeft {
    text-align: left !important;
}

.alignCenter {
    text-align: center !important;
}

.tableActions {
    text-align: right !important;
}

.buttonRightIcon {
    margin-left: 8px;
    vertical-align: middle;
    margin-right: 0 !important;
}

.largeButton {
    font-size: 20px !important;
    padding: 30px !important;
}

.detailsLabel {
    display: inline-block;
    min-width: 120px;

    & + strong {
        display: inline-block;
        vertical-align: top;
    }
}

.mat-mdc-button-base.roundedButton {
    border-radius: 15px;
}

.mat-mdc-outlined-button.roundedButton.inactiveBtn {
    border-color: mat.get-color-from-palette($tyyp-primaryGray, 700) !important;
    color: mat.get-color-from-palette($tyyp-primaryGray, 800);
}

.mat-mdc-outlined-button.websiteButton {
    border-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 700) !important;
    border-width: 1px;
    padding-left: 30px;
    padding-right: 30px;
    height: 50px;
    font-weight: normal !important;
    font-size: 16px;
    color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 700) !important;
}

p.listActions {
    .mat-mdc-button-base, .mat-mdc-button-base + .mat-mdc-button-base-base {
        margin: 0 8px 8px 0;
    }
}

@media only screen and (max-width: 500px) {
    p.listActions {
        .mat-mdc-button-base {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.rowFollowedByExtraRow {
    td {
        border-bottom: 0;
    }
}

// layout blocks
.wrapper {
    width: 94%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.box {
    background-color: mat.get-color-from-palette($tyyp-primaryGray);
    padding: 30px;
    margin-bottom: 30px;

    .box {
        padding: 0;
        margin-bottom: 0;
    }

    & > :last-child {
        margin-bottom: 0 !important;
    }

    & > .formRow:first-child,
    & > .mat-mdc-form-field:first-child,
    & > p:first-child {
        .mat-mdc-form-field-flex {
            padding-top: 0;
        }
    }
}

.box.highlight {
    background-color: mat.get-color-from-palette($tyyp-primaryBlue);
    color: white;

    * {
        color: white;
    }

    .mat-mdc-unelevated-button.mat-primary {
        background-color: white !important;
    }

    .mat-mdc-outlined-button.mat-primary {
        border-color: white !important;
    }
}

.row {
    display: flex;
    gap: 40px;

    .dialog {
        display: inline-block;
        width: auto;
        flex: 1;
    }
}

.withSidebar {
    @include sidebar;
}

.formRow {
    display: flex;
    gap: 10px;

    mat-form-field {
        display: inline-block;
        width: auto;
        flex: 1;

        .mat-mdc-form-field-infix {
            width: auto;
        }
    }
}

@media only screen and (max-width: 500px) {
    .formRow {
        flex-direction: column;
    }

    .searchBox .formRow {
        flex-direction: row;
    }
}

// trial cta
course-trial-cta {
    @media only screen and (max-height: 750px) {
        .modalWrapper {
            padding: 40px 0 !important;
        }
    }

    .modal {
        @media only screen and (max-height: 700px) {
            transform-origin: 50% 0;
            transform: scale(0.85);
        }

        @media only screen and (max-height: 700px) {
            transform: scale(0.75);
        }
    }
}

// material styles
.mat-mdc-button-base {
    white-space: nowrap;
}

h1, h2, h3, h4, h5, h6 {
    mat-icon {
        vertical-align: middle;
    }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 320px !important;
}

mat-slide-toggle .mdc-form-field > label {
    padding-left: 8px;
}

course-organization-class-table, course-organization-user-table {
    mat-slide-toggle .mdc-form-field > label {
        font-weight: 500;
    }
}

mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.mdc-checkbox__checkmark {
    color: white !important;
}

course-organization-licenses .billingMail {
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: white;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: white !important;
    }
}

course-organization-exercise-form .mat-mdc-tab-body-content {
    padding: 0 12px !important;
}

.passwordField {
    .mat-mdc-form-field-hint-wrapper {
        padding-right: 0;

        mat-hint {
            width: 100%;
            text-align: right;
        }
    }
}

.mat-mdc-menu-item {
    color: mat.get-color-from-palette($tyyp-primaryBlue);
}

.mat-drawer-container {
    background-color: transparent !important;
}

.mat-mdc-tooltip {
    white-space: pre-line;
}

.mat-mdc-form-field {
    width: 100%;
}

#stateField, #postalCodeField {
    width: 160px;
}

@media only screen and (max-width: 500px) {
    #stateField, #postalCodeField {
        width: 100%;

        mat-form-field {
            width: 100%;
        }
    }
}

mat-radio-group mat-radio-button {
    display: block !important;
    margin: 14px 0;
}

h2 .mat-mdc-icon-button {
    vertical-align: middle;
}

a .mat-icon {
    vertical-align: middle;
}

.mat-mdc-unelevated-button, .mat-mdc-outlined-button {
    .mat-icon {
        margin-right: 8px;
    }
}

.mat-mdc-fab, .mat-mdc-mini-fab {
    box-shadow: none !important;
}

mat-tab-header {
    margin-bottom: 30px;
}

.mat-mdc-tab {
    mat-icon, .svgIcon {
        margin-right: 8px;
    }
}

.mdc-tab--active .svgIcon {
    opacity: 1 !important;
}

.hideTabs mat-tab-header {
    display: none !important;
}

.userMenu {
    min-width: 200px !important;
}

// sideNav
.withSideNav {
    display: flex;
    gap: 30px;
    align-items: flex-start;

    .article {
        flex: 1;
        width: calc(100% - 280px);
    }
}

.sideNav {
    position: sticky;
    top: 10px;
    width: 280px;
    border-right: 1px solid mat.get-color-from-palette($tyyp-primaryGray);

    & > ul {
        box-sizing: border-box;
        width: 280px;
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;

        ul {
            margin: 8px 0;
            padding-left: 50px;
        }
    }

    mat-icon, .svgIcon {
        margin-right: 8px;
        color: mat.get-color-from-palette($tyyp-primaryGray, 700);
    }

    .svgIcon {
        width: 24px;
        height: 24px;
        //filter: invert(94%) sepia(12%) saturate(92%) hue-rotate(154deg) brightness(87%) contrast(96%);
    }

    a {
        display: flex;
        text-decoration: none;
        border-right: 3px solid transparent;
        padding: 5px 5px 5px 0;
        color: black;

        &:hover {
            color: mat.get-color-from-palette($tyyp-secondaryLightBlue);

            mat-icon {
                color: mat.get-color-from-palette($tyyp-secondaryLightBlue);
            }

            //.svgIcon {
            //    filter: invert(68%) sepia(43%) saturate(533%) hue-rotate(170deg) brightness(101%) contrast(98%);
            //}
        }
    }

    .active {
        font-weight: bold;

        mat-icon {
            color: black;
        }

        //.svgIcon {
        //    filter: invert(0%) sepia(94%) saturate(26%) hue-rotate(82deg) brightness(98%) contrast(106%);
        //}
    }

    .deepestActive {
        border-right-color: mat.get-color-from-palette($tyyp-primaryBlue);
    }
}

// (svg) icons
.svgIcon {
    //filter: invert(20%) sepia(83%) saturate(2511%) hue-rotate(197deg) brightness(95%) contrast(101%);
    vertical-align: middle;
}

h1 .svgIcon {
    height: 50px;
    width: 50px;
}

h2 .svgIcon {
    height: 30px;
    width: 30px;
}

h1, h2 {
    & > mat-icon, & > .svgIcon {
        margin-right: 8px;
    }
}

.mat-mdc-outlined-button, .mat-mdc-unelevated-button, .mdc-button {
    .svgIcon {
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }
}

.mat-mdc-menu-item, .mdc-tab {
    .svgIcon {
        width: 30px;
        height: 30px;
    }
}

.mat-mdc-menu-item .svgIcon {
    margin-right: 16px;
}

.largeButton {
    .svgIcon {
        width: 20px;
        height: 20px;
    }
}

//.mat-flat-button.mat-primary .svgIcon {
//    filter: invert(100%) sepia(1%) saturate(7500%) hue-rotate(210deg) brightness(109%) contrast(107%);
//}

.mat-mdc-mini-fab .svgIcon {
    width: 30px;
    height: 30px;
    //filter: invert(100%) sepia(1%) saturate(7500%) hue-rotate(210deg) brightness(109%) contrast(107%);
    margin: -3px auto 0 auto;
}

.mat-mdc-mini-fab mat-icon {
    margin: auto;
}

// keyboard button
.keyboardButton {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    border: none;
    position: relative;
    padding: 0;
    background-color: transparent;

    &:before, span {
        transition: 50ms ease-in;
    }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: mat.get-color-from-palette($tyyp-red);
        border-radius: 20px 20px 10px 10px;
    }

    span {
        display: inline-block;
        background-color: mat.get-color-from-palette($tyyp-red, 200);
        border-radius: 10px;
        padding: 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        width: calc(100% - 10px);
        box-sizing: border-box;

        position: relative;
        margin: 0 5px 10px 5px;
    }

    &:hover {
        &:before {
            top: 3px;
            height: calc(100% - 3px);
        }

        span {
            margin-top: 3px;
            margin-bottom: 7px;
        }


        //margin-top: 3px;
        //padding-bottom: 7px;
    }
}

.orangeKeyboardButton {
    span {
        background-color: mat.get-color-from-palette($tyyp-primaryOrange);
        color: white;
    }
}

.blueKeyboardButton {
    &:before {
        background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 700);
    }

    span {
        background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue);
        color: white;
    }
}

// enroll students support article expansion panel
.supportArticleContent {
    .enrollTypeIcon {
        width: 80px;
        max-width: unset;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0 20px 0 0;
    }
}

.mat-expansion-panel-header-title {
    font-size: 16px;
    color: mat.get-color-from-palette($tyyp-primaryBlue);
}

// placeholders
.placeholder {
    width: 96%;
    max-width: 400px;
    margin: 50px auto 0 auto;
    text-align: center;

    img {
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        margin: 0;
        font-size: 30px;
    }

    h2 + p {
        margin-top: 4px;
    }

    p {
        font-size: 20px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-weight: 200;
    }
}

.placeholderWrapper {
    min-height: calc(100dvh - 320px);
    position: relative;

    .placeholder {
        margin-top: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// comparison table (in support articles)
.comparisonTable {
    margin: 40px 0;

    th, td {
        text-align: center;
        border-bottom: 1px solid mat.get-color-from-palette($tyyp-primaryGray);
    }

    tr {
        height: 60px;
    }

    tr > :first-child {
        text-align: left;
    }

    .tableInfo {
        font-size: 12px;
    }

    .checkIcon {
        color: mat.get-color-from-palette($tyyp-green);
    }

    .closeIcon {
        color: mat.get-color-from-palette($tyyp-red);
    }
}

// support detail
course-support-detail {
    p {
        font-size: 16px;
    }

    figure {
        max-width: 100%;
        margin: 40px 0;
        text-align: center;

        img {
            max-width: calc(100% - 40px);
        }
    }

    figcaption {
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-size: 14px;
        font-style: italic;
    }
}

.supportArticleContent {
    padding-bottom: 20px;

    h2 {
        margin-top: 60px;
    }

    h3 {
        margin-top: 40px;
    }

    img {
        border: 4px solid mat.get-color-from-palette($tyyp-secondaryLightBlue, 100);
        border-radius: 4px;
        padding: 8px;
        margin: 8px 0;
        max-width: calc(100% - 40px);
    }

    ol {
        @include orderedList;

        h2 {
            margin-top: 20px;
        }
    }
}

// support link
.headerWithSupport {
    display: flex;
    align-items: center;

    & > span {
        flex: 1;
    }
}

.supportLink {
    &:before {
        font-family: "Material Icons Outlined";
        content: "\e8fd";
        font-size: 20px;
        margin-right: 8px;
        vertical-align: middle;
    }
}

.supportButton {
    text-decoration: none;
    font-size: 14px;
    background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 50);
    border: 1px solid mat.get-color-from-palette($tyyp-secondaryLightBlue, 700);
    font-weight: 500;
    border-radius: 16px;
    color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 700);
    padding: 2px 10px 3px 6px;

    & > span {
        display: inline-block;
        vertical-align: middle;
        margin-top: -1px;
    }

    &:visited {
        color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 700);
    }

    &:before {
        content: url("assets/images/icons/help.svg");
        margin-right: 6px;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        display: inline-block;
    }
}

// speed slider
.speedSlider {
    .speedLabel {
        font-weight: bold;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-size: 16px;
    }

    mat-slider {
        width: 200px;
        margin: 0 20px;
    }
}

// quickStats
.quickStats {
    display: flex;
    gap: 50px;
    justify-content: center;
    padding: 10px 0 30px 0;

    & > div {
        display: flex;
        gap: 20px;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        padding: 12px 20px 12px 20px;
        width: 200px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-weight: bold;
        font-size: 12px;
    }

    img {
        width: 40px;
        max-height: 40px;
    }

    .statNumber {
        font-size: 30px;
        padding: 8px 0 0 0;
    }
}

@media only screen and (max-width: 900px) {
    .quickStats {
        gap: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .quickStats {
        flex-direction: column;
        align-items: center;
    }
}

// activity button
.activityButton {
    display: inline-block;
    border-radius: 50%;
    margin: 0 2px;
    padding: 4px;
    border: 2px solid transparent;
    height: 30px;

    a {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        background-color: mat.get-color-from-palette($tyyp-primaryGray);
        color: black;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        padding-top: 6px;
        text-decoration: none;
    }

    &.completed a {
        background-color: mat.get-color-from-palette($tyyp-primaryOrange);
        color: white;
    }

    &.active {
        border: 2px solid mat.get-color-from-palette($tyyp-primaryBlue);
        //background-color: mat.get-color-from-palette($tyyp-primaryGray);
        background-color: white;

        a {
            background-color: mat.get-color-from-palette($tyyp-primaryOrange);
            color: white;
            cursor: default;
        }
    }
}


// course
.key {
    display: inline-block;
    padding: 15px 25px;
    background-color: white;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    font-family: 'Noto Sans Mono', monospace;
    color: #666666;
    font-size: 30px;
}

.correctKey {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen);
    border-color: mat.get-color-from-palette($tyyp-green);
}

.correctedKey {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightYellow);
    border-color: mat.get-color-from-palette($tyyp-secondaryYellow);
}

.wrongKey {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightRed);
    border-color: mat.get-color-from-palette($tyyp-red);
}

.pulseKey {
    animation: bounceKey 2000ms linear infinite;

    &:before {
        content: "";
        display: block;
        border-radius: 10px;
        background-color: mat.get-color-from-palette($tyyp-primaryOrange);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        animation: pulseKey 2000ms ease-out infinite;
    }
}

@keyframes pulseKey {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }

    40% {
        opacity: 0;
        transform: scale(1.4);
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    }
}

@keyframes bounceKey {
    0% {
        transform: rotate(0);
    }

    3% {
        transform: rotate(2deg);
    }

    6% {
        transform: rotate(0);
    }

    9% {
        transform: rotate(-2deg);
    }

    12% {
        transform: rotate(0);
    }

    15% {
        transform: rotate(2deg);
    }

    18% {
        transform: rotate(0);
    }

    21% {
        transform: rotate(-2deg);
    }

    24% {
        transform: rotate(0deg);
    }

    27% {
        transform: rotate(0deg);
    }
}

@keyframes wrongKeyShake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-3px);
    }

    75% {
        transform: translateX(6px);
    }

    100% {
        transform: translateX(0px);
    }
}

.char {
    position: relative;
    vertical-align: bottom;
    width: 22px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 4px;
    font-size: 30px;
    font-family: 'Noto Sans Mono', monospace;
    color: mat.get-color-from-palette($tyyp-primaryGray, 900);
    margin-right: 1px;
}

.wrongTypedCharacter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.showWrongTypedCharacter {
    animation: showWrongTypedCharacter 1200ms ease-out forwards;
}

@keyframes showWrongTypedCharacter {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

// snackbars
.successSnackbar .mdc-snackbar__surface {
    background-color: white;
    color: mat.get-color-from-palette($tyyp-primaryBlue);
    border-left: 5px solid mat.get-color-from-palette($tyyp-green);
    font-weight: bold;

    simple-snack-bar .mat-mdc-snack-bar-label:before {
        font-family: "Material Icons Outlined";
        content: "\e5ca";
        color: mat.get-color-from-palette($tyyp-green);
        font-size: 20px;
        vertical-align: middle;
        margin-right: 8px;
    }
}

.infoSnackbar .mdc-snackbar__surface {
    background-color: white;
    color: mat.get-color-from-palette($tyyp-primaryBlue);
    border-left: 5px solid mat.get-color-from-palette($tyyp-secondaryLightBlue);
    font-weight: bold;
}

.errorSnackbar .mdc-snackbar__surface {
    background-color: white;
    color: mat.get-color-from-palette($tyyp-primaryBlue);
    border-left: 5px solid mat.get-color-from-palette($tyyp-red);
    font-weight: bold;

    simple-snack-bar .mat-mdc-snack-bar-label:before {
        font-family: "Material Icons Outlined";
        content: "\e645";
        color: mat.get-color-from-palette($tyyp-red);
        font-size: 20px;
        vertical-align: middle;
        margin-right: 8px;
    }
}

// dialogs
.dialog {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 50);
    color: mat.get-color-from-palette($tyyp-primaryBlue);
    padding: 20px;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 95dvw;

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    .dialogActions {
        border-top: 1px solid mat.get-color-from-palette($tyyp-secondaryLightBlue, 200);
        text-align: right;
        margin: 0;
        padding: 10px 0 0 0;

        button, a {
            margin: 10px;
        }
    }

    h2 {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    h2:before {
        font-size: 26px;
        font-weight: bold;
        vertical-align: bottom;
    }
}

.dialog.info {
    h2:before {
        font-family: "Material Icons Outlined";
        content: "\e88e";
        color: mat.get-color-from-palette($tyyp-primaryBlue);
    }
}

.dialog.error {
    background-color: mat.get-color-from-palette($tyyp-red, 50);
    color: mat.get-color-from-palette($tyyp-red, 700);

    h2 {
        color: mat.get-color-from-palette($tyyp-red, 700);
    }

    h2:before {
        font-family: "Material Icons Outlined";
        content: "\e000";
        color: mat.get-color-from-palette($tyyp-red, 700);
    }

    .dialogActions {
        border-color: mat.get-color-from-palette($tyyp-red);
    }
}

.dialog.success {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen, 100);
    color: mat.get-color-from-palette($tyyp-green, 700);

    h2 {
        color: mat.get-color-from-palette($tyyp-green, 700);
    }

    h2:before {
        font-family: "Material Icons Outlined";
        content: "\e876";
        color: mat.get-color-from-palette($tyyp-green, 700);
    }

    .dialogActions {
        border-color: mat.get-color-from-palette($tyyp-green);
    }
}

// intro exercise types
course-type-letter-intro, course-adaptive-intro {
    .keyIntro, course-keyboard {
        display: block;
        width: 749px;
        margin-left: auto;
        margin-right: auto;
    }

    .keyIntro {
        font-size: 24px;
        height: 268px;
        line-height: 28px;
        position: relative;
    }

    .instructionsContainer {
        display: flex;
        gap: 50px;
        align-items: flex-end;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        min-height: 200px;
    }

    .instructions {
        flex: 1;
    }

    .instructionPage {
        transition: 400ms ease-in-out;
        background-color: white;
        border-radius: 10px;
        padding: 30px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        position: absolute;
        bottom: 20px;
        width: calc(100% - 160px);
        box-sizing: border-box;
        opacity: 0;

        h3 {
            font-size: 20px;
            margin-top: 0;
        }

        p {
            font-size: 16px;
            font-weight: 500;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: calc(100% - 2px);
            bottom: 40px;
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 34px solid white;
        }
    }

    .teacher {
        width: 120px;
    }

    .activePage {
        opacity: 1;
        bottom: 0;
    }
}

// dialogBox
.dialogBox {
    margin: 20px auto;
    border: 1px solid mat.get-color-from-palette($tyyp-primaryGray);
    max-width: 95dvw;

    h2, h3, p {
        margin: 20px;
    }

    .dialogActions {
        background-color: mat.get-color-from-palette($tyyp-primaryGray);
        text-align: right;
        margin: 0;
        padding: 10px;

        button, a {
            margin: 10px;
        }
    }
}

.box .dialogBox {
    background-color: white;
    border-color: mat.get-color-from-palette($tyyp-primaryGray, 700);
}

// modal
.modalOpen {
    overflow: hidden;
}

course-modal {
    h1 {
        margin-top: 0;
    }

    .modal>h3:first-child {
        margin-top: 0;
    }
}

@media only screen and (max-width: 500px) {
    course-modal {
        h1 {
            .svgIcon {
                display: none;
            }
        }
    }

    .headerWithSupport {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
}


p.modalFormControls {
    display: flex;
    border-top: 1px solid mat.get-color-from-palette($tyyp-primaryGray, 700);
    padding-top: 28px;
    margin-top: 30px;
    margin-bottom: -2px;
    text-align: right;

    .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
        padding: 4px 16px;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .cancelBtn {
        color: mat.get-color-from-palette($tyyp-primaryGray, 900);
    }

    .alignRight {
        flex: 1;

        .mat-mdc-button-base + .mat-mdc-button-base {
            margin-left: 8px;
        }
    }
}

.userLink {
    white-space: nowrap;
    text-decoration: none;

    course-avatar {
        margin-right: 6px;
    }
}

.avatarCol {
    width: 72px;
}

// panel
@media only screen and (max-width: 500px) {
    .panelContent {
        h1:first-of-type {
            margin-top: 0;
        }
    }
}

// chips
.chip {
    display: inline-block;
    vertical-align: middle;
    padding: 0 4px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    line-height: 16px;

    &.largeChip {
        font-size: 14px;
        line-height: 20px;
    }
}

.chip.success, .chip.supremeleader {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen);
    color: mat.get-color-from-palette($tyyp-green);
}

.chip.info, .chip.instructor, .chip.company {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue);
    color: mat.get-color-from-palette($tyyp-primaryBlue);
}

.chip.error, .chip.archived {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightRed);
    color: mat.get-color-from-palette($tyyp-red);
}

.chip.admin, .chip.school {
    background-color: mat.get-color-from-palette($tyyp-primaryOrange);
    color: white;
}

.chip.student, .chip.credit {
    background-color: mat.get-color-from-palette($tyyp-secondaryYellow);
    color: white;
}

h1 .chip {
    margin-left: 8px;
}

// searchBar
.searchBar {
    display: flex;
    gap: 50px;
}

.searchBarActions {
    flex: 1;
    padding-top: 10px;
}

.searchBox {
    display: block;

    .formRow {
        align-items: center;
    }

    .mat-mdc-mini-fab {
        margin-top: -20px;
    }
}

// products
.priceNumber {
    span {
        font-size: 24px;
    }
}

// shopping cart
.quantityInput {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.discountCode mat-form-field {
    margin-bottom: -18px;

    .mdc-text-field {
        background-color: white !important;
    }
}

// licenses stats
.licenseStatContainer {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
}

.licenses {
    border-radius: 10px;
    padding: 12px;
    display: flex;
    gap: 12px;
    text-align: center;
    background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 200);

    .svgIcon {
        width: 40px;
    }
}

.numBall {
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    padding-top: 12px;
}

.licenseBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    background-color: white;
    border-radius: 5px;
    padding: 12px;
    color: mat.get-color-from-palette($tyyp-primaryBlue);

    h3 {
        margin: 0 0 5px 0;
        align-content: center;
        font-size: 16px;
        flex: 1;
    }

    .numBall {
        margin: 10px auto;
        background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 200);
        width: 65px;
        height: 65px;
        font-size: 30px;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}

.availableLicenses {
    background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen, 200);
    flex-basis: 60%;

    .licenseBlock {
        cursor: pointer;

        &:hover {
            background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen, 100);
        }
    }

    .numBall {
        background-color: mat.get-color-from-palette($tyyp-green);
        color: white;
    }

    h3, p {
        color: mat.get-color-from-palette($tyyp-green);
    }
}

.upgradePossible {
    background-color: mat.get-color-from-palette($tyyp-primaryOrange, 100);
    flex-basis: 20%;

    .licenseBlock[href]:hover {
        background-color: mat.get-color-from-palette($tyyp-primaryOrange, 50);
    }

    .numBall {
        background-color: mat.get-color-from-palette($tyyp-primaryOrange);
        color: white;
    }

    h3, p {
        color: mat.get-color-from-palette($tyyp-primaryOrange);
    }
}

.withoutLicense {
    background-color: mat.get-color-from-palette($tyyp-red, 100);
    flex-basis: 20%;

    .licenseBlock[href]:hover {
        background-color: mat.get-color-from-palette($tyyp-red, 50);
    }

    .numBall {
        background-color: mat.get-color-from-palette($tyyp-red);
        color: white;
    }

    h3, p {
        color: mat.get-color-from-palette($tyyp-red);
    }
}

// tabbed auth form titles
course-tabbed-auth-form-layout .formSectionHeader {
    display: flex;
    gap: 80px;
    align-items: center;
    margin-bottom: 40px;

    .heroImg {
        width: 40%;
    }

    .websiteSectionTitle {
        margin: 0;
        text-align: left;
        max-width: 100%;

        h2 {
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 800px) {
        gap: 0;

        img {
            display: none;
        }
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 20px;
    }
}

// fancy option selection
.fancyOptions {
    display: flex;
    flex-wrap: wrap;
}

.fancyOption {
    width: calc(50% - 41px);
    text-align: center;
    padding: 20px;
    cursor: pointer;

    &:nth-of-type(odd) {
        border-right: 1px solid mat.get-color-from-palette($tyyp-primaryGray);
    }

    &:nth-of-type(2) ~ & {
        border-top: 1px solid mat.get-color-from-palette($tyyp-primaryGray);
    }

    h2 {
        margin-bottom: 5px;
        font-size: 20px;
    }

    p {
        color: black;
    }

    & > mat-icon {
        font-size: 70px;
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
        color: mat.get-color-from-palette($tyyp-primaryGray);
        transition: color 200ms, transform 200ms;
    }

    hr {
        background-color: white;
        border: 1px solid mat.get-color-from-palette($tyyp-primaryGray);
        width: 50px;
        margin: 10px auto 10px auto;
    }

    &:hover, &.selectedOption {
        background-color: mat.get-color-from-palette($tyyp-primaryGray);

        & > mat-icon {
            color: mat.get-color-from-palette($tyyp-primaryBlue);
            transform: scale(1.2);
        }
    }

    &.disabledOption {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background-color: transparent;

            mat-icon {
                color: white;
                transform: scale(1);
            }
        }
    }
}

// fancy radio group
.fancyRadioGroup {
    display: flex;
    gap: 16px;
    padding: 8px 0;

    mat-radio-button {
        flex: 1;
        transition: border-color 200ms ease-out, background-color 200ms ease-out;
        border-radius: 16px;
        border: 1px solid mat.get-color-from-palette($tyyp-primaryBlue);
        border-bottom-width: 8px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-weight: bold;
        margin: 0;
        text-align: center;

        img {
            max-width: 90%;
        }

        .mdc-radio {
            display: none;
        }

        .mdc-form-field {
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            label {
                width: 100%;
                height: 100%;
                margin: auto 0;
                padding: 8px 16px 16px 16px;
                font-weight: bold;
                color: mat.get-color-from-palette($tyyp-primaryBlue);

                span {
                    display: block;
                    padding-top: 5px;
                }
            }

            img {
                transition: transform 200ms ease-out;
            }
        }

        &:not(.disabled) .mdc-form-field label {
            cursor: pointer !important;
        }

        &:not(.disabled):hover {
            border-color: mat.get-color-from-palette($tyyp-secondaryLightBlue);
            color: mat.get-color-from-palette($tyyp-secondaryLightBlue);
            background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 100);

            img {
                transform: scale(1.1);
            }
        }

        &.disabled {
            border-color: mat.get-color-from-palette($tyyp-primaryGray);
        }
    }

    .mat-mdc-radio-checked, .mat-mdc-radio-checked:hover {
        border-color: mat.get-color-from-palette($tyyp-green);
        color: mat.get-color-from-palette($tyyp-green);
        background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen);
        position: relative;

        &:after {
            box-sizing: border-box;
            font-family: "Material Icons Outlined";
            content: "\e876";
            display: block;
            position: absolute;
            top: -10px;
            left: -10px;
            background-color: mat.get-color-from-palette($tyyp-green);
            border: 2px solid white;
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            padding-top: 4px;
            font-size: 20px;
        }
    }
}

// compact style (org order page)
.compactStyle.fancyRadioGroup mat-radio-button label {
    padding-bottom: 0 !important;
}

// fancy radio group exercise form exception
@media only screen and (min-width: 1100px) {
    course-organization-exercise-form .fancyRadioGroup mat-radio-button .mdc-form-field label {
        padding: 8px;

        img {
            max-width: 100%;
        }
    }
}

// shared styles
.before {
    text-decoration: line-through;
    color: mat.get-color-from-palette($tyyp-primaryGray, 800);
    font-size: 16px;
}

.now {
    color: mat.get-color-from-palette($tyyp-green);
    font-size: 20px;
}

// high contrast
.highContrast {
    .pulseKey:before {
        background-color: #E3145A !important;
    }

    course-activity .box:not(.reportBox) {
        background-color: #333;

        course-type-text {
            .char {
                color: #6bd703;
            }

            .charHolder {
                position: relative;
                padding: 3px 0;
                margin-bottom: 7px;
            }

            .cursor {
                background-color: transparent;
                border: 3px solid #E3145A;
                position: absolute;
                top: 0;
                width: calc(100% - 6px);
                height: calc(100% - 6px);
            }
        }

        course-type-letter-intro, course-type-keys {
            .keyWrapper {
                margin: 18px 16px;
            }

            .key {
                background-color: #444;
                border-color: #666;
                color: #6bd703;
                border-width: 3px;
            }

            .cursor {
                background-color: #E3145A;
            }

            .activeKey {
                border-color: #E3145A;
            }
        }

        course-falling-keys {
            .key {
                background-color: #444;
                border-color: #666;
                color: #6bd703;
            }

            .keyRow {
                &.activeRow {
                    background-color: #ff7dae;

                    .key {
                        border-color: #E3145A;
                        background-color: #E3145A;
                    }
                }

                &.correctRow {
                    background-color: #00BC96;

                    .key {
                        background-color: #027760;
                        border-color: #027760;
                        color: black;
                    }
                }

                &.wrongRow {
                    background-color: #fa7c64;

                    .key {
                        background: repeating-linear-gradient(-45deg, #F23815, #F23815 5px, #ec5237 5px, #ec5237 10px);
                        border-color: #d25541;
                        color: black;
                    }
                }

                &.correctedRow {
                    background-color: #fda62d;

                    .key {
                        background-color: #c97805;
                        border-color: #c97805;
                        color: black;
                    }
                }
            }
        }

        course-type-letter-intro, course-adaptive-intro {
            .instructionPage {
                background-color: #666;

                &:after {
                    border-left-color: #666;
                }
            }

            .instructionPage {
                color: #6bd703;

                h3 {
                    color: #6bd703;
                }
            }
        }

        course-activity-progress-bar {
            .progress {
                background-color: #666;
            }

            .progressBar {
                background-color: #E3145A;
            }
        }

        course-keyboard {
            .background {
                fill: #666;
            }

            .keyShape {
                fill: #444;
            }

            .hiddenZone {
                fill: #444;
            }

            .keyboard {
                fill: #6bd703;
            }

            .homeLine {
                stroke: #6bd703;
            }

            .correctPressedButton .keyShape {
                stroke: #027760;
            }

            .wrongPressedButton {
                .keyShape {
                    fill: #fa7c64;
                    stroke: #d25541;
                }

                .keyText {
                    fill: black;
                }

                .homeLine {
                    stroke: black;
                }
            }

            .activeButton {
                .keyShape {
                    fill: #E3145A;
                }

                .keyText {
                    fill: white;
                }

                .homeLine {
                    stroke: white;
                }
            }

            .pulse {
                stroke: #E3145A;
            }

            // NOTE: these class names are not reliable. Updates on the source might need a fix here!
            .cls-36, .cls-68 {
                fill: #4b4747;
            }

            .cls-66 {
                stroke: #6bd703;
            }

            // keyboard knowledge heatmap
            .knowledgeGood, .knowledgeAverage, .knowledgeBelowAverage, .knowledgeBad {
                .keyText {
                    fill: black;
                }

                .homeLine {
                    stroke: black;
                }
            }

            .knowledgeGood {
                .keyShape {
                    fill: #6bd703;
                }
            }

            .knowledgeAverage {
                .keyShape {
                    fill: #fda62d;
                }
            }

            .knowledgeBelowAverage {
                .keyShape {
                    fill: #fa7c64;
                }
            }

            .knowledgeBad {
                .keyShape {
                    fill: #F23815
                }
            }
        }
    }
}

// website transitions
.ssr {
    .slideUp, .slideLeft, .slideRight {
        transform: none;
        opacity: 1;
    }
}

.slideUp {
    transition: 800ms ease-out;
}

.slideLeft, .slideRight {
    transition: 600ms ease-out;
}

.slideUp, .slideLeft, .slideRight {
    opacity: 0;

    &.inViewport {
        opacity: 1;
        transform: none;
    }
}

.slideUpMedium, .slideLeftMedium, .slideRightMedium {
    transition-duration: 600ms;
}

.slideUpMini, .slideLeftMini, .slideRightMini {
    transition-duration: 400ms;
}

.slideUp {
    transform: translateY(40px);
}

.slideUpMedium {
    transform: translateY(20px);
}

.slideUpMini {
    transform: translateY(10px);
}

.slideLeft {
    transform: translateX(30px);
}

.slideLeftMedium {
    transform: translateX(20px);
}

.slideLeftMini {
    transform: translateX(10px);
}

.slideRight {
    transform: translateX(-30px);
}

.slideRightMedium {
    transform: translateX(-20px);
}

.slideRightMini {
    transform: translateX(-10px);
}

// info highlight (f.e. in delete dialogs to highlight what you're deleting)
.infoHighlight {
    text-align: center;
    background-color: mat.get-color-from-palette($tyyp-secondaryLightBlue, 50);
    color: mat.get-color-from-palette($tyyp-primaryBlue);
    font-weight: 500;
    font-size: 18px;
    padding: 14px 20px;
    margin: 20px 0;
    border-radius: 5px;
}

// mobile warning
course-mobile-warning {
    p, li {
        font-size: 16px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
    }
}

// website components
// website section title
.websiteSectionTitle {
    width: 800px;
    max-width: calc(100% - 60px);
    margin: auto;
    text-align: center;

    h2 {
        font-size: 34px;
        margin-bottom: 0;
    }

    h2 + p {
        margin-top: 8px;
    }

    p {
        font-size: 20px;
        color: mat.get-color-from-palette($tyyp-primaryBlue);
        font-weight: 200;
    }
}

// checklist
.checklist {
    padding-left: 0;

    li {
        display: flex;
        gap: 4px;
        padding: 2px 0;
        list-style-type: none;
        font-size: 16px;

        span {
            flex: 1;
        }
    }

    li:before {
        content: 'check';
        font-family: "Material Icons Outlined";
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
        color: mat.get-color-from-palette($tyyp-green);
    }
}

// faq
course-faq-question .answer {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    * {
        font-size: 14px;
    }
}

// shop
.purchaseInfo {
    .discount {
        margin-bottom: 8px;

        p {
            display: inline-block;
            padding: 4px 8px 1px 8px;
            border-radius: 3px;
            color: mat.get-color-from-palette($tyyp-green);
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;

            .discountPercentage {
                display: inline-block;
                text-align: center;
                font-weight: 500;
                margin-right: 8px;
                font-size: 18px;
                vertical-align: middle;
                margin-bottom: 3px;
                background-color: mat.get-color-from-palette($tyyp-green);
                color: white;
                box-sizing: border-box;
                height: 50px;
                width: 50px;
                padding-top: 15px;
                border-radius: 25px;
            }
        }
    }

    .pricing {
        .price {
            display: inline-block;
            margin: auto;
            font-size: 40px;
            font-weight: bold;
            color: mat.get-color-from-palette($tyyp-primaryOrange);
            text-align: right;
        }

        .priceNumber {
            line-height: 0.7;

            span {
                font-size: 16px;
            }
        }

        .exclIncl {
            display: block;
            font-size: 12px;
            font-weight: normal;
            color: mat.get-color-from-palette($tyyp-primaryGray, 800);
            line-height: 1;
        }

        .originalPrice {
            display: block;
            color: mat.get-color-from-palette($tyyp-primaryGray, 800);
            font-weight: normal;
            line-height: 0.7;

            .priceNr {
                text-decoration: line-through;
                font-size: 17px;
            }

            .msrp {
                font-size: 12px;
                margin-right: 10px;
            }
        }
    }
}

// checkout
course-purchase-upgrade-to-full {
    .upgradeToFull {
        .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
            background-color: mat.get-color-from-palette($tyyp-green);
        }

        .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
            background-color: mat.get-color-from-palette($tyyp-secondaryLightGreen, 700) !important;
        }
    }
}

// language overrides
body[lang-code="fr"] {
    course-purchase .productDescription .cta .productBtn {
        min-width: 300px;
    }

    @media only screen and (max-width: 1000px) {
        course-purchase .productDescription .cta {
            flex-direction: column;

            .productBtn, .infoBtn {
                flex: unset !important;
            }
        }
    }
}
