// Shared styles
@mixin themeBg {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom center;
}

@mixin decoration {
    position: absolute;
}

// HOME ROW
.homeRowTheme {
    @include themeBg;
    background-image: url("assets/images/themes/homeRow/bg.svg");

    .leftBranch {
        @include decoration;
        left: 0;
        top: 4%;
        height: 30%;

        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }

    .rightBranch {
        @include decoration;
        right: 0;
        top: 25%;
        height: 30%;
    }

    .redBird {
        @include decoration;
        width: 70px;
        top: 14%;
        right: 10%;
    }

    .clouds1, .clouds2 {
        @include decoration;
        animation-duration: 60s;
        animation-play-state: paused;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        &.inViewport {
            animation-play-state: running;
        }
    }

    .clouds1 {
        top: 2%;
        animation-delay: -5s;
        width: 40%;
        animation-name: clouds1;
    }

    @keyframes clouds1 {
        from {
            right: -40%;
        }
        to {
            right: 100%;
        }
    }

    .clouds2 {
        top: 25%;
        animation-delay: -30s;
        width: 30%;
        animation-duration: 50s;
        animation-name: clouds2;
    }

    @keyframes clouds2 {
        from {
            right: -30%;
        }
        to {
            right: 100%;
        }
    }
}

.chapter .box.homeRowTheme {
    padding-bottom: 13dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 250px; /* 1920 * 0.13 */
    }
}

// TOP ROW
.topRowTheme {
    background-color: #ffb7b7 !important;
    @include themeBg;
    background-image: url("assets/images/themes/topRow/bg.svg");

    .activityButton.active {
        background-color: #ffb7b7;
    }

    .leftBranch {
        @include decoration;
        left: 0;
        bottom: 60%;
        width: 25%;

        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }

    .rightBranch {
        @include decoration;
        right: 0;
        bottom: 40%;
        width: 25%;

        @media only screen and (max-width: 1000px) {
            bottom: 30%;
        }
    }

    .bgDots {
        @include decoration;
        left: 0;
        bottom: calc(100dvw * 0.43);
        width: 100%;

        @media only screen and (min-width: 1920px) {
            bottom: 826px; /* 1920 * 0.43 */
        }
    }
}

.chapter .box.topRowTheme {
    padding-bottom: 15dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 288px; /* 1920 * 0.15 */
    }
}

.reportBox.topRowTheme {
    h2 {
        color: #c73a3a;
    }
}

// BOTTOM ROW
.bottomRowTheme {
    background-color: #9ed9fa !important;
    @include themeBg;
    background-image: url("assets/images/themes/bottomRow/bg.svg");

    .activityButton.active {
        background-color: #9ed9fa;
    }

    .decorations {
        .plant {
            @include decoration;
            right: -2%;
            bottom: 50%;
            width: 17%;
        }

        .sub {
            @include decoration;
            top: 25%;
            width: 140px;
            height: 243px; /* height + the translateY animation to avoid the animation stopping when the parent is out of view */
            animation-duration: 30s;
            animation-play-state: paused;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: -5s;
            animation-name: sub;

            img {
                @include decoration;
                animation: subUpDown 4s alternate infinite ease-in-out;
                animation-play-state: paused;
            }

            &.inViewport {
                animation-play-state: running;

                img {
                    animation-play-state: running;
                }
            }
        }

        @keyframes sub {
            0% {
                left: -140px;
            }
            100% {
                left: 100%;
            }
        }

        @keyframes subUpDown {
            to {
                transform: translateY(100px);
            }
        }

        .turtle {
            @include decoration;
            right: 15%;
            top: 10%;
            width: 120px;
        }
    }
}

.chapter .box.bottomRowTheme {
    padding-bottom: 10dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 192px; /* 1920 * 0.1 */
    }
}

.reportBox.bottomRowTheme {
    h2 {
        color: white;
    }
}

// LINE ENDS
.lineEndsTheme {
    @include themeBg;
    background-image: url("assets/images/themes/lineEnds/bg.svg");
}

.chapter .box.lineEndsTheme {
    padding-bottom: 20dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 384px; /* 1920 * 0.2 */
    }
}

// SHIFT
.shiftTheme {
    background-color: #287fcb !important;
    @include themeBg;
    background-image: url("assets/images/themes/shift/bg.svg");

    .activityButton.active {
        background-color: #287fcb;
    }

    .decorations {
        .stars {
            @include decoration;
            right: 5%;
            top: 40%;
            width: 3%;
        }

        .comet {
            @include decoration;
            left: 5%;
            top: 10%;
            width: 15%;
        }

        .alien {
            @include decoration;
            right: 5%;
            bottom: 30%;
            width: 17%;
            animation: alienUpDown 3s alternate infinite ease-in-out;
            animation-play-state: paused;

            &.inViewport {
                animation-play-state: running;
            }
        }

        @keyframes alienUpDown {
            to {
                transform: translatey(50px);
            }
        }

        .planet {
            @include decoration;
            left: 2%;
            bottom: 40%;
            width: 10%;
        }

        .satellite {
            @include decoration;
            left: 3%;
            top: 30%;
            width: 16%;
        }

        .ufo {
            @include decoration;
            width: 20%;
            animation-duration: 20s;
            animation-play-state: paused;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: -5s;
            animation-name: ufo;

            &.inViewport {
                animation-play-state: running;
            }
        }

        @keyframes ufo {
            0% {
                right: -18%;
                top: 20%;
            }
            100% {
                right: 100%;
                top: 0;
            }
        }
    }
}

.chapter .box.shiftTheme {
    padding-bottom: 16dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 307px; /* 1920 * 0.16 */
    }
}

.reportBox.shiftTheme {
    h2 {
        color: white;
    }
}

// BASIC
.basicTheme {
    background-color: #c6e6f8 !important;
    @include themeBg;
    background-image: url("assets/images/themes/basic/bg.svg");

    .activityButton.active {
        background-color: #c6e6f8;
    }
}

.chapter .box.basicTheme {
    padding-bottom: 25dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 480px; /* 1920 * 0.25 */
    }
}

.reportBox.basicTheme {
    @media only screen and (min-width: 1600px) {
        background-position: 50% calc(100% + 100px);
    }
}

// NUMBERS
.numbersTheme {
    background-color: #4fa6e4 !important;
    @include themeBg;
    background-image: url("assets/images/themes/numbers/bg.svg");

    .activityButton.active {
        background-color: #4fa6e4;
    }

    .reportContent>h2 {
        color: white;
    }

    .decorations {
        .windowL1, .windowL2, .windowR1, .windowR2 {
            @include decoration;
            width: 16.9%;
        }

        .windowL1, .windowL2 {
            left: 4.2%;

            @media only screen and (max-width: 1000px) {
                display: none;
            }
        }

        .windowR1, .windowR2 {
            right: 4.5%;
        }

        .windowL1 {
            bottom: calc(31.8dvw + 30%);

            @media only screen and (min-width: 1920px) {
                bottom: calc(611px + 30%); /* 1920 * 0.318 */
            }
        }

        .windowR1 {
            bottom: calc(34.3dvw + 30%);

            @media only screen and (min-width: 1920px) {
                bottom: calc(659px + 30%); /* 1920 * 0.343 */
            }
        }

        .windowL2, .windowR2 {
            bottom: calc(18.4dvw + 15%);

            @media only screen and (min-width: 1920px) {
                bottom: calc(353px + 15%); /* 1920 * 0.184 */
            }
        }

        @media only screen and (max-width: 1200px) {
            .windowL1 {
                bottom: calc(31.8dvw + 50%);
            }

            .windowR1 {
                bottom: calc(34.3dvw + 50%);
            }

            .windowL2, .windowR2 {
                bottom: calc(18.4dvw + 25%);
            }
        }

        .fillBricks1, .fillBricks2 {
            @include decoration;
            width: 4%;
        }

        .fillBricks1 {
            top: 14%;
            left: 16%;
        }

        .fillBricks2 {
            right: 6%;
            top: 40%;
        }

        .outlineBricks1, .outlineBricks2, .outlineBricks3, .outlineBricks4, .outlineBricks5 {
            @include decoration;
            width: 10%;
            opacity: 0.5;
        }

        .outlineBricks1 {
            top: 5%;
            left: 30%;
        }

        .outlineBricks2 {
            top: 32%;
            right: 30%;
        }

        .outlineBricks3 {
            bottom: 30%;
            left: 30%;
        }

        .outlineBricks4 {
            top: 5%;
            right: 8%;
        }

        .outlineBricks5 {
            top: 48%;
            left: 22%;
        }
    }
}

.chapter .box.numbersTheme {
    padding-bottom: 23dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 442px; /* 1920 * 0.23 */
    }
}

// USEFUL CHARS
.usefulCharsTheme {
    @include themeBg;
    background-image: url("assets/images/themes/usefulChars/bg.svg");

    .decorations {
        .clouds1, .clouds2, .clouds3, .clouds4 {
            @include decoration;
            animation-play-state: paused;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            &.inViewport {
                animation-play-state: running;
            }
        }

        .clouds1, .clouds4 {
            width: 40%;
            animation-duration: 60s;
            animation-name: usefulCharClouds1;
        }

        .clouds2, .clouds3 {
            width: 30%;
            animation-duration: 50s;
            animation-name: usefulCharClouds2;
        }

        .clouds1 {
            top: 5%;
            animation-delay: -5s;
        }

        .clouds2 {
            top: 15%;
            animation-delay: -30s;
        }

        .clouds3 {
            top: 40%;
            animation-delay: -20s;
        }

        .clouds4 {
            top: 60%;
            animation-delay: -5s;
        }

        @keyframes usefulCharClouds1 {
            from {
                right: -40%;
            }
            to {
                right: 100%;
            }
        }

        @keyframes usefulCharClouds2 {
            from {
                right: -30%;
            }
            to {
                right: 100%;
            }
        }

        .airplane {
            @include decoration;
            width: 18%;
            top: 15%;
            left: 5%;

            @media only screen and (max-width: 1000px) {
                left: unset;
                right: 10%;
                top: 10%;
            }
        }

        .balloon {
            @include decoration;
            width: 125px;
            height: 262px; /* height + the translateY animation to avoid the animation stopping when the parent is out of view */
            bottom: 40%;
            right: 7%;
            animation-duration: 30s;
            animation-play-state: paused;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: -5s;
            animation-name: balloon;

            img {
                @include decoration;
                animation: balloonUpDown 4s alternate infinite ease-in-out;
                animation-play-state: paused;
            }

            &.inViewport {
                animation-play-state: running;

                img {
                    animation-play-state: running;
                }
            }
        }

        @keyframes balloon {
            0% {
                right: -125px;
            }
            100% {
                right: 100%;
            }
        }

        @keyframes balloonUpDown {
            to {
                transform: translatey(100px);
            }
        }
    }
}

.chapter .box.usefulCharsTheme {
    padding-bottom: 22dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 422px; /* 1920 * 0.22 */
    }
}

// ADVANCED
.advancedTheme {
    background-color: #77ccfd !important;
    @include themeBg;
    background-image: url("assets/images/themes/advanced/bg.svg");

    .activityButton.active {
        background-color: #77ccfd;
    }

    .decorations {
        .bubble1, .bubble2, .bubble3 {
            @include decoration;
            width: 5%;
        }

        .bubble1 {
            top: 5%;
            left: 10%;
        }

        .bubble2 {
            top: 25%;
            right: 17%;
            transform: rotate(100deg);
        }

        .bubble3 {
            bottom: 30%;
            left: 30%;
        }

        .mermaid {
            @include decoration;
            width: 30%;
            top: 10%;
            left: -10%;

            @media only screen and (max-width: 1000px) {
                transform: rotate(250deg);
                left: unset;
                right: -10%;
                top: 30%;
            }
        }

        .buoy1 {
            @include decoration;
            width: 20%;
            top: 10%;
            right: -5%;
        }

        .buoy2 {
            @include decoration;
            width: 20%;
            top: 30%;
            left: -5%;
        }
    }
}

.chapter .box.advancedTheme {
    padding-bottom: 22dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 422px; /* 1920 * 0.22 */
    }
}

// PATTERNS
.patternsTheme {
    @include themeBg;
    background-image: url("assets/images/themes/patterns/bg.svg");

    .decorations {
        .vine {
            @include decoration;
            width: 40%;
            top: 0;
            left: 0;
        }
    }
}

.chapter .box.patternsTheme {
    padding-bottom: 25dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 480px; /* 1920 * 0.25 */
    }
}

// EXPERT
.expertTheme {
    background-color: #c6e6f8 !important;
    @include themeBg;
    background-image: url("assets/images/themes/expert/bg.svg");

    .activityButton.active {
        background-color: #c6e6f8;
    }
}

.chapter .box.expertTheme {
    padding-bottom: 25dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 480px; /* 1920 * 0.25 */
    }
}

// REPETITION
.repetitionTheme {
    background-color: #c6e6f8 !important;
    @include themeBg;
    background-image: url("assets/images/themes/repetition/bg.svg");

    .activityButton.active {
        background-color: #c6e6f8;
    }
}

.chapter .box.repetitionTheme {
    padding-bottom: 25dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 480px; /* 1920 * 0.25 */
    }
}

// BASIC EXAM
.basicExamTheme {
    @include themeBg;
    background-image: url("assets/images/themes/basicExam/bg.svg");

    &:not(.examFlatTheme) {
        background-color: #ffb7b7 !important;
    }

    &.examFlatTheme {
        background-image: url("assets/images/themes/basicExam/bgFlat.svg");
    }

    &.examSuccessTheme {
        background-image: url("assets/images/themes/exam/bgSuccess.svg");
    }
}

.chapter .box.basicExamTheme {
    padding-bottom: 15dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 288px; /* 1920 * 0.15 */
    }
}

// EXAM
.examTheme {
    @include themeBg;
    background-image: url("assets/images/themes/exam/bg.svg");

    &.examFlatTheme {
        background-image: url("assets/images/themes/exam/bgFlat.svg");
    }

    &.examSuccessTheme {
        background-image: url("assets/images/themes/exam/bgSuccess.svg");
    }

    .decorations {
        .sun {
            @include decoration;
            width: 80px;
            height: calc(80px + 15dvw); /* height + the translateY animation to avoid the animation stopping when the parent is out of view */
            top: 0;
            animation-duration: 40s;
            animation-play-state: paused;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-name: sun;

            @media only screen and (min-width: 1920px) {
                height: 368px; /* 1920 * 0.15 + 80 */
            }

            img {
                @include decoration;
                animation: sunUpDown 20s alternate infinite ease-out;
                animation-play-state: paused;

                @media only screen and (min-width: 1920px) {
                    animation-name: sunUpDownHD;
                }
            }

            &.inViewport {
                animation-play-state: running;

                img {
                    animation-play-state: running;
                }
            }
        }

        @keyframes sun {
            0% {
                right: -80px;
            }
            100% {
                right: 100%;
            }
        }

        @keyframes sunUpDown {
            from {
                transform: translateY(15dvw) scale(1);
            }


            to {
                transform: translateY(0) scale(1.8);
            }
        }

        @keyframes sunUpDownHD {
            from {
                transform: translateY(288px) scale(1);
            }

            to {
                transform: translateY(0) scale(1.8);
            }
        }
    }
}

.chapter .box.examTheme {
    padding-bottom: 25dvw;

    @media only screen and (min-width: 1920px) {
        padding-bottom: 480px; /* 1920 * 0.25 */
    }
}
